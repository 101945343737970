@font-face {
    font-family: 'Lexend';
    font-weight: 800;
    src: url(./assets/fonts/Lexend-ExtraBold.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 700;
    src: url(./assets/fonts/Lexend-Bold.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 600;
    src: url(./assets/fonts/Lexend-SemiBold.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 500;
    src: url(./assets/fonts/Lexend-Medium.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 400;
    src: url(./assets/fonts/Lexend-Regular.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 300;
    src: url(./assets/fonts/Lexend-Light.ttf);
}
@font-face {
    font-family: 'Lexend';
    font-weight: 200;
    src: url(./assets/fonts/Lexend-Thin.ttf);
}

.flyingBtn {
    z-index: 1001;
    transform-origin: center bottom;
    transform: scale(1);
  }
  